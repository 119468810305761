@import 'master';

sales-pin-pad {
  .pin-container {
    @include absolute(top 0 left 0);
    z-index: 6;
    width: 100%;
    height: 100%;
    @include flex-center-center();

    &.stick-right {
      right: 0;
      left: auto;
    }

    &.single-box .pin-pad {
      .pin-pad-header {
        margin-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .pin-pad-numbers {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 20px;

        .pin-cell {
          margin-right: 20px;
          margin-bottom: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .pin-pad {
      @include flex-center-center();
      flex-direction: column;
      font-size: 18px;
      width: 300px;
      position: relative;

      &.wrong-pin {
        animation: shake 500ms;
      }

      .close-icon {
        @include align-vertically();
        right: 1rem;
        z-index: 2;
        width: 15px;
        height: 15px;
        cursor: pointer;

        svg {
          fill: var(--pin-pad-title-fore);
        }
      }

      .pin-pad-header {
        @include flex-center-center();
        flex-direction: column;
        background-color: var(--pin-pad-body-bkg);
        color: var(--pin-pad-body-fore);
        width: 100%;
        margin-bottom: 10px;
        border-radius: var(--border-radius-modals);
        text-align: center;

        .pin-pad-title {
          position: relative;
          width: 100%;
          padding: 1rem 2rem;
          background-color: var(--pin-pad-title-bkg);
          color: var(--pin-pad-title-fore);
          border-top-left-radius: var(--border-radius-modals);
          border-top-right-radius: var(--border-radius-modals);
        }

        .pin-pad-label {
          margin: 10px 0;
        }

        input {
          text-align: center;
          font-size: 18px;
          letter-spacing: 10px;
          font-family: Verdana, sans-serif;
          border: 1px solid var(--pin-pad-title-bkg);
          box-shadow: none;
          margin: 10px;
          margin-top: 0;
          width: 85%;

          &[readonly] {
            cursor: default;
          }

          &.wrong-pin {
            border: 2px solid red;
          }
        }
      }

      .pin-pad-numbers {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        background-color: var(--pin-pad-body-bkg);
        color: var(--pin-pad-body-fore);
        width: 100%;
        padding: 10px;
        border-radius: var(--border-radius-modals);
      }

      .pin-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &:last-child .pin-cell {
          margin-bottom: 0;
        }
      }

      .pin-cell {
        @include flex-center-center();
        width: 33%;
        background: var(--pin-pad-button-bkg);
        cursor: pointer;
        margin-right: 5px;
        margin-bottom: 5px;
        border-radius: var(--border-radius-buttons);

        &:last-child {
          margin-right: 0;
        }

        &:active {
          opacity: 0.9;
        }

        button {
          padding-top: 100%;
          position: relative;
          span {
            @include align-both-directions();
            color: var(--pin-pad-button-fore);
          }
        }
      }

      .pin-cell icon {
        @include align-both-directions();
        height: 15px;
        width: 15px;

        svg {
          fill: var(--pin-pad-button-fore);
        }
      }
    }
  }
}
