@import 'master';

quick-nav #quick-nav {
  @include fixed(bottom 0 left 0);
  z-index: 5;
  height: var(--bottom-nav-height);
  display: flex;
  opacity: 0.9;
  transition: transform 300ms ease;
  transform: translateX(calc(-1 * var(--bottom-nav-height)));

  @media print {
    display: none;
  }

  .main-container {
    display: flex;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  }

  &.hide .main-container {
    display: none;
  }

  &.show-back-button {
    transform: translateX(0);
  }

  &.hide.show-back-button {
    .main-container {
      display: flex;

      #primary-modules,
      #secondary-modules,
      #nav-tools {
        display: none;
      }

      .logo-container {
        display: flex;
        border-right: none;

        #back-button {
          border-right: none;
        }

        .logo {
          display: none;
        }
      }
    }
  }

  &.full-width:not(.hide) {
    right: 0;
    background-color: var(--quick-nav-background-alpha-high);
    opacity: 1;
    transform: none;

    &.show-back-button {
      .main-container {
        .logo-container {
          transform: translateX(0);
        }
      }
    }

    .main-container {
      width: 100%;

      .logo-container,
      #nav-tools {
        flex: 0 0;
        border: none;
        background-color: transparent;
      }

      .logo-container {
        transition: transform 300ms ease;
        transform: translateX(calc(-1 * var(--bottom-nav-height)));

        .logo {
          --logoHeight: var(--bottom-nav-height);
          width: calc(var(--logoHeight) * 2);
          margin: 0 1rem;
        }
      }

      button > icon {
        width: calc(var(--bottom-nav-height) / 2.5);
        height: calc(var(--bottom-nav-height) / 2.5);
      }

      #primary-modules {
        flex: 1 1 auto;
        align-items: center;
        background-color: transparent;
        overflow-x: auto;
        overflow-y: hidden;
        @supports (overflow-x: overlay) {
          overflow-x: overlay;
        }

        button {
          white-space: nowrap;
          width: auto;
          height: auto;
          margin: 0 0.5rem;
          padding: 0.5rem 1rem;
          border-radius: var(--border-radius-buttons);
          transition: background 200ms ease;
          color: var(--quick-nav-foreground);

          &.selected {
            background: linear-gradient(
              0,
              var(--quick-nav-selected-alpha-high) 0%,
              var(--quick-nav-selected) 40%
            );
            color: var(--quick-nav-selected-foreground);
            box-shadow: 0 4px 7px #3a3a3a;

            h3 {
              font-weight: bold;
            }
          }

          h3 {
            font-weight: normal;
            font-size: 18px;
          }

          favorites-counter + h3, icon + h3 {
            margin-left: 0.5rem;
          }

          @media only screen and (max-width: 1024px) {
            padding: 0.5rem;
            h3 {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  #nav-tools {
    @include flex-center-center();

    .arrow {
      width: calc(var(--bottom-nav-height) / 2);
      height: var(--bottom-nav-height);

      &.hide {
        opacity: 0;
      }

      button {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
      }

      &.left {
        transform: rotate(180deg);
      }
    }
  }

  icon-button {
    button {
      background-color: transparent;
      box-shadow: none;
      border: none;
    }
  }

  #back-button {
    background-color: var(--global-active-background);
    color: var(--global-active-foreground);
    border-right: 1px solid var(--background2-alpha-low);

    svg {
      fill: var(--global-active-foreground);
    }
  }

  .logo-container {
    border-right: 1px solid var(--background2-alpha-low);
    background-color: var(--quick-nav-background);
    color: var(--quick-nav-foreground);
    @include flex-center-center();

    .logo {
      align-self: center;
      --logoHeight: calc(var(--bottom-nav-height) * 0.8);
      height: var(--logoHeight);
      width: calc(var(--logoHeight) * (16 / 9));
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin: 0 0.5rem;
      cursor: pointer;
      @include flex-center-center();
    }
  }

  button {
    width: var(--bottom-nav-height);
    height: var(--bottom-nav-height);
    transition: width 300ms linear;
    @include flex-center-center();

    & > .icon-center {
      width: calc(var(--bottom-nav-height) / 2);
      height: calc(var(--bottom-nav-height) / 2);

      &.with-short-name {
        width: unset;
        height: unset;
      }
      & > icon {
        width: calc(var(--bottom-nav-height) / 2);
        height: calc(var(--bottom-nav-height) / 2);
      }
      .short-name {
        color: var(--quick-nav-foreground);
        font-size: 14px;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(var(--bottom-nav-height) - 20px);
      }
    }
    & > favorites-counter + .icon-center {
      width: unset;
      height: unset;
    }

    & > .with-short-name {
      & > icon {
        width: calc(calc(var(--bottom-nav-height) - 20px) / 2);
        height: calc(calc(var(--bottom-nav-height) - 20px) / 2);
      }
    }

    & > icon {
      width: calc(var(--bottom-nav-height) / 2);
      height: calc(var(--bottom-nav-height) / 2);
    }

    icon svg {
      fill: var(--quick-nav-foreground);
    }

    favorites-counter {
      icon svg {
        fill: var(--favoritesforeground);
      }

      &.has-count.icon-only {
        icon svg {
          fill: var(--favoritesactive);
        }
      }
    }

    &.selected {
      icon svg {
        fill: var(--quick-nav-selected);
      }

      favorites-counter {
        .counter {
          background-color: var(--quick-nav-selected);
          color: var(--quick-nav-selected-foreground);
        }
        icon svg {
          fill: var(--favoritesforeground);
        }
      }
    }

    &:hover,
    &:active {
      opacity: 0.85;
    }

    &.expanded-brochure-button {
      width: (calc(var(--bottom-nav-height) + var(--bottom-nav-height) / 4));
    }

    favorites-counter {
      width: calc(var(--bottom-nav-height) / 2);
      height: calc(var(--bottom-nav-height) / 2);
    }
  }

  #primary-modules {
    display: flex;
    background-color: var(--quick-nav-background);
    color: var(--quick-nav-foreground);
    padding: 0 10px;
  }

  .centering-container {
    margin: auto;
    display: flex;
  }

  #secondary-modules {
    display: flex;
    background-color: var(--quick-nav-background);
    color: var(--quick-nav-foreground);
    @include absolute(left 100%);
    z-index: -1;
    border-left: 1px solid var(--background2-alpha-low);
    padding: 0 10px;
    transition: transform 150ms ease-in-out;
    transform: translateX(-100%);

    &.expanded {
      transform: translateX(0);
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    }

    .expander {
      background-color: var(--background3);
      color: var(--foreground3);
      border-left: 1px solid var(--background2-alpha-low);
      @include absolute(left 100%);
      border-top-right-radius: var(--border-radius-quick-nav);
      width: calc(var(--bottom-nav-height) * 0.65);
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);

      icon {
        transition: transform 500ms ease;
        transform: rotateY(0);
        width: calc(var(--bottom-nav-height) / 3);
        height: calc(var(--bottom-nav-height) / 3);

        svg {
          fill: var(--foreground3);
        }
      }

      &.expanded {
        background-color: var(--global-active-background);
        border-left-color: transparent;

        icon {
          transform: rotateY(180deg);

          svg {
            fill: var(--global-active-foreground);
          }
        }
      }
    }
  }

  .top-border-radius {
    border-top-right-radius: var(--border-radius-quick-nav);
  }

  @include media('<desktop') {
    &.is-standalone {
      bottom: auto;
      top: calc(
        var(--current-floating-page-title-height) + 1rem
      ); // matches floating-menu-buttons in ism
      left: 1rem;
      align-items: flex-start;

      #back-button {
        width: auto;
        height: auto;
        border-radius: var(--border-radius-buttons);
        box-shadow: 0 1px 5px var(--transparent-gray);
        padding: 0.4em;
        border: 1px solid transparent;
        font-size: 22px; // matches floating-menu-buttons in ism

        icon {
          width: 1em;
          height: 1em;
        }
      }
    }
  }
}
