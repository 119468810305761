import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { PushToGoogleTagManager } from '../../utility/helpers/google-tag-manager';
import { sortBySortOrder } from '../../utility/helpers/sorters';
import { IBaseContentJson, PageVersion } from '../../utility/interfaces/page';
import { ModuleGridItemVM } from '../../utility/models/module-grid-item-vm';
import { PageVM } from '../../utility/models/page-vm';
import { FavoritesService } from '../../utility/services/favorites.service';
import { HomeBuyerService } from '../../utility/services/home-buyer/home-buyer.service';
import {
  INavigationOptions,
  NavigationService,
  PageTitle
} from '../../utility/services/navigation.service';
import { OverlayService, OverlayTypes } from '../../utility/services/overlay.service';
import { PageService } from '../../utility/services/page.service';
import { SalesRepresentativeService } from '../../utility/services/sales-representative.service';
import { SettingsService } from '../../utility/services/settings.service';
import { ThemeService } from '../../utility/services/theme.service';
import { ViewModelFactoryService } from '../../utility/services/vm-factory.service';
import { ToastService } from '../toast/toast.service';

import { ISalesRep } from '@ml/common';

@Component({
  selector: 'quick-nav',
  templateUrl: './quick-nav.component.html',
  styleUrls: ['./quick-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickNavComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scrollingContainer') scrollingContainer: ElementRef<HTMLElement>;
  subs: Subscription[] = [];
  isShowing = true;
  logoUrl: string;
  allModules: ModuleGridItemVM[] = [];
  primaryModules: ModuleGridItemVM[] = [];
  secondaryModules: ModuleGridItemVM[] = [];
  isExpanded = false;
  pinModule: ModuleGridItemVM;
  registrationModule: ModuleGridItemVM;
  brochureModule: ModuleGridItemVM;
  brochureUniqueName = PageTitle.Brochure;
  totalFavorites = 0;
  pageTitleToGoBackTo: PageTitle;
  backButtonTooltip: string;
  usePermanentBottomNav = false;
  showScrollArrows = false;
  canScrollLeft = false;
  canScrollRight = false;
  showShortName = false;
  showRegistration = 'None';
  isStandalone = false;

  constructor(
    private navigator: NavigationService,
    private overlay: OverlayService,
    private cdr: ChangeDetectorRef,
    private pageService: PageService,
    private settingsService: SettingsService,
    private salesRepService: SalesRepresentativeService,
    private toaster: ToastService,
    private favorites: FavoritesService,
    private vmFactory: ViewModelFactoryService,
    private homeBuyerService: HomeBuyerService,
    private themeService: ThemeService
  ) {}

  ngOnInit() {
    this.isShowing = this.navigator.AllowAppNavigation;

    this.isStandalone = this.navigator.IsInStandaloneMode;

    this.logoUrl = this.settingsService.get('QuickNavCompanyLogo');

    this.usePermanentBottomNav = !!this.settingsService.get('UsePermanentBottomNav');

    this.allModules = this.getAllAsModules(this.pageService.getAll(PageVersion.MyScp));
    this.brochureModule = this.allModules.find(x => x.UniqueName === this.brochureUniqueName);

    if (this.usePermanentBottomNav) {
      this.primaryModules = this.allModules;
    } else {
      this.primaryModules = this.allModules.filter(x => x.IsInPrimaryQuickNav);
      this.secondaryModules = this.allModules.filter(x => x.IsInSecondaryQuickNav);
    }

    const isPINLoginEnabled = this.settingsService.get('EnablePINLogin');
    if (isPINLoginEnabled) {
      this.pinModule = new ModuleGridItemVM({ UniqueName: ModuleGridItemVM.SalesPinUniqueName });
      if (!this.usePermanentBottomNav) this.primaryModules.push(this.pinModule);
    }

    this.showRegistration = this.settingsService.get('QuickNavRegistration');

    this.setupSubscriptions(
      isPINLoginEnabled,
      !!this.brochureModule,
      this.showRegistration !== 'None'
    );

    this.showShortName = this.settingsService.get('QuickNavTitlesUnderIcons');

    if (this.showShortName) {
      this.themeService.updateBottomNavHeight();
    }

    this.cdr.markForCheck();
  }

  ngAfterViewInit() {
    window.setTimeout(this.checkIfScrollIsNeeded, 0);
    window.addEventListener('resize', this.checkIfScrollIsNeeded, true);

    if (this.scrollingContainer?.nativeElement) {
      this.scrollingContainer.nativeElement.addEventListener('scroll', this.checkScrollLeftRight);
      // Trigger the scroll event to set the initial state
      this.checkScrollLeftRight();
    }
  }

  ngOnDestroy() {
    this.subs.forEach(x => x.unsubscribe());
    window.removeEventListener('resize', this.checkIfScrollIsNeeded, true);

    if (this.scrollingContainer?.nativeElement)
      this.scrollingContainer?.nativeElement.removeEventListener(
        'scroll',
        this.checkScrollLeftRight
      );
  }

  private setupSubscriptions(
    isPINLoginEnabled: boolean,
    hasBrochure: boolean,
    isRegistrationEnabled: boolean
  ) {
    if (isPINLoginEnabled)
      this.subs.push(this.salesRepService.current$.subscribe(this.handleSalesRepChange));

    this.subs.push(
      this.overlay.state$
        .pipe(filter(state => state.targetedComponent === OverlayTypes.QuickNav))
        .subscribe(state => {
          this.isShowing = state.isShowing;
          this.cdr.markForCheck();
        })
    );

    this.subs.push(
      this.navigator.current$.subscribe(data => {
        const updateModSelection = (mod: ModuleGridItemVM): ModuleGridItemVM => {
          if (this.isModuleSelected(mod, data)) return { ...mod, IsSelected: true };
          else return { ...mod, IsSelected: false };
        };
        this.primaryModules = this.primaryModules.map(updateModSelection);
        this.secondaryModules = this.secondaryModules.map(updateModSelection);
        this.cdr.markForCheck();
      })
    );

    this.subs.push(
      this.navigator.backButton$.subscribe((pageTitleToGoBackTo: PageTitle) => {
        this.updateBackButtonPage(pageTitleToGoBackTo);
        this.cdr.markForCheck();
      })
    );

    if (isRegistrationEnabled)
      this.subs.push(
        this.homeBuyerService.current$.subscribe(hb => {
          this.registrationModule = this.vmFactory.createHomeBuyerRegisterModule(hb);

          if (this.showRegistration === 'Show in Primary') {
            this.updateRegistrationModuleInArray(this.primaryModules);
          }
          if (this.showRegistration === 'Show in Secondary') {
            this.updateRegistrationModuleInArray(this.secondaryModules);
          }
          this.cdr.markForCheck();
        })
      );

    if (hasBrochure)
      this.subs.push(
        this.favorites.current$.subscribe(session => {
          this.totalFavorites = session.TotalFavoritesCount;
          this.cdr.detectChanges();
        })
      );
  }

  private updateRegistrationModuleInArray(arr: ModuleGridItemVM[]) {
    const index = arr.findIndex(x => x.UniqueName === 'HomeBuyerRegister');
    if (index > -1) {
      arr[index] = this.registrationModule;
    } else {
      arr.push(this.registrationModule);
    }
  }

  private handleSalesRepChange = (rep: ISalesRep) => {
    this.pinModule = this.vmFactory.createSalesPinModule(rep);

    const updatePinModule = (mod: ModuleGridItemVM): ModuleGridItemVM => {
      if (mod.UniqueName === ModuleGridItemVM.SalesPinUniqueName) return this.pinModule;
      else return mod;
    };
    this.primaryModules = this.primaryModules.map(updatePinModule);
    this.secondaryModules = this.secondaryModules.map(updatePinModule);
    this.cdr.markForCheck();
  };

  private isModuleSelected(mod: ModuleGridItemVM, data: INavigationOptions) {
    if (mod.IsEnergyEfficiency) {
      return mod.Id === data.RouteParams?.PageId;
    } else {
      return (
        mod.UniqueName === data.PageTitle ||
        (mod.IsCustomPage && mod.Id === data.RouteParams?.PageId)
      );
    }
  }

  async handleModuleNavigation(module: ModuleGridItemVM) {
    const shouldCheckForHomeBuyerAutoPop =
      this.usePermanentBottomNav &&
      this.settingsService.get('PopupHomeBuyerRegistrationOnFirstTouch') &&
      module.UniqueName !== ModuleGridItemVM.HomeBuyerRegisterUniqueName &&
      module.UniqueName !== ModuleGridItemVM.SalesPinUniqueName;
    if (shouldCheckForHomeBuyerAutoPop) {
      await this.homeBuyerService.checkForAutoPop(false);
    }

    this.overlay.hide(OverlayTypes.SalesPinPad);
    this.overlay.hide(OverlayTypes.HomeBuyerRegistration);

    PushToGoogleTagManager({
      eventName: 'ClickQuickNav',
      eventType: 'App Click Interaction',
      eventValue: module.Title
    });

    if (module.UniqueName === ModuleGridItemVM.SalesPinUniqueName) {
      this.handleSalesRepAction();
    } else if (module.UniqueName === ModuleGridItemVM.HomeBuyerRegisterUniqueName) {
      this.handleHomeBuyerRegistrationAction();
    } else if (module.IsDownloadLink) {
      this.pageService.getPageDownloadableContent(module.Id);
    } else if (module.IsCustomPage) {
      this.navigator.go({ PageTitle: PageTitle.CustomPage, RouteParams: { PageId: module.Id } });
    } else if (module.UniqueName === this.brochureUniqueName && !this.totalFavorites) {
      this.toaster.showInfo('Not Yet!', 'Try favoriting a few items before visting this page');
    } else if (module.IsEnergyEfficiency) {
      this.navigator.go({
        PageTitle: PageTitle.EnergyEfficiency,
        RouteParams: { PageId: module.Id }
      });
    } else {
      this.navigator.go({ PageTitle: module.UniqueName as PageTitle });
    }
  }

  handleSalesRepAction() {
    const currentSalesRep = this.salesRepService.current;
    if (currentSalesRep) {
      this.toaster.showInfo(
        `${currentSalesRep.FirstName} ${currentSalesRep.LastName} has been logged out`
      );
      this.salesRepService.logout();
    } else {
      this.overlay.hide(OverlayTypes.HomeBuyerRegistration);
      this.overlay.show(OverlayTypes.SalesPinPad);
    }
  }

  async handleHomeBuyerRegistrationAction() {
    const currentHB = this.homeBuyerService.current;
    if (currentHB) {
      if (
        await this.toaster.showConfirm(
          'Are you sure?',
          `You are about to log out ${currentHB.FirstName} ${currentHB.LastName} and then clear all session data`
        )
      ) {
        this.toaster.showInfo(`${currentHB.FirstName} ${currentHB.LastName} has been logged out`);
        this.favorites.logoutAndClearSession();
      }
    } else {
      this.overlay.hide(OverlayTypes.SalesPinPad);
      this.overlay.show(OverlayTypes.HomeBuyerRegistration);
    }
  }

  handleResetSession() {
    this.toaster
      .showConfirm(
        'Are you sure?',
        'You are about to reset your session data. This action cannot be undone.'
      )
      .then((shouldContinue: boolean) => {
        if (!shouldContinue) return;
        // TODO - clear any future session data here like filters, visited pages, etc
        // It says save but it won't because the HB isn't logged in (this reset button will only be shown for non-logged in HBs)
        this.favorites.resetFavoritesAndSave();
      });
  }

  handleScrollStep(direction: number) {
    this.scrollingContainer?.nativeElement.scrollBy({ left: 150 * direction, behavior: 'smooth' });
  }

  checkIfScrollIsNeeded = () => {
    const container = this.scrollingContainer?.nativeElement;
    if (container?.scrollWidth > container?.clientWidth) this.showScrollArrows = true;
    else this.showScrollArrows = false;
    this.cdr.markForCheck();
  };

  checkScrollLeftRight = () => {
    if (!this.scrollingContainer?.nativeElement) return;

    window.requestAnimationFrame(() => {
      const element = this.scrollingContainer?.nativeElement;
      this.canScrollLeft = element.scrollLeft > 0;
      this.canScrollRight = element.clientWidth + element.scrollLeft < element.scrollWidth;
      this.cdr.markForCheck();
    });
  };

  handleExpandToggle() {
    this.isExpanded = !this.isExpanded;
  }

  goHome() {
    this.navigator.go({ PageTitle: PageTitle.Homepage });
  }

  private getAllAsModules(pages: PageVM<IBaseContentJson>[]): ModuleGridItemVM[] {
    return pages
      .filter(x => x.IsActive)
      .sort(sortBySortOrder)
      .map(x => new ModuleGridItemVM(x))
      .filter(m => !m.IsHiddenInNav);
  }

  trackById(index: number, item: ModuleGridItemVM) {
    return item.Id;
  }

  handleGoBack() {
    this.navigator.go({ PageTitle: this.pageTitleToGoBackTo });
  }

  private updateBackButtonPage(pageTitleToGoBackTo: PageTitle) {
    this.pageTitleToGoBackTo = pageTitleToGoBackTo;
    const moduleTitle = this.allModules.find(m => m.UniqueName === this.pageTitleToGoBackTo)?.Title;
    this.backButtonTooltip = moduleTitle ? `Return to ${moduleTitle}` : '';
  }
}
