@mixin absolute($args) {
  @include position(absolute, $args);
}

@mixin fixed($args) {
  @include position(fixed, $args);
}

@mixin relative($args) {
  @include position(relative, $args);
}

@mixin position($position, $args) {
  position: $position;
  // list of offsets to check for in $args
  $offsets: top right bottom left;

  // loop through $offsets
  @each $o in $offsets {
    // if current offset found in $args
    // assigns its index to $i or 'false' if not found
    $i: index($args, $o);

    @if $i
      // offset in $args?
      and
      $i +
      1 <=
      length($args)
      // offset value in range?
      and
      type-of(nth($args, $i + 1)) ==
      number
    {
      #{$o}: nth($args, $i + 1);
    }
  }
}

@mixin align-vertically($position: absolute) {
  // assumes position absolute if none passed
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}

@mixin align-horizontally() {
  @include absolute(left 50%);
  transform: translateX(-50%);
}

@mixin align-both-directions() {
  @include absolute(top 50% left 50%);
  transform: translate(-50%, -50%);
}

@mixin flex-center-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin animation-fadeIn($speed: 250ms, $timingFunction: ease) {
  animation: fadeIn $speed $timingFunction;
}

@mixin sameWidthHeight($val: 100%) {
  width: $val;
  height: $val;
}

@mixin hoverVisibilityScrollbarOverflowY {
  overflow-y: auto;
  @supports (overflow-y: overlay) {
    overflow-y: overlay;
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px rgb(35, 35, 35, 0);
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 10px 10px rgb(35, 35, 35, 0.3);
        &:hover {
          box-shadow: inset 0 0 10px 10px rgb(35, 35, 35, 0.7);
        }
      }
    }
  }
}

@mixin myscpToolsQuickButton {
  width: 45px;
  height: 45px;
  background-color: var(--background6-alpha-high);
  border: 1px solid var(--background5);
  border-top: none;
  @include flex-center-center();
  transition: background-color 200ms ease;

  &:first-child {
    border-top: 1px solid var(--background5);
  }

  &:active,
  &.selected {
    background-color: var(--global-active-background-alpha-high);

    icon svg {
      fill: var(--global-active-foreground);
    }
  }

  icon {
    width: 50%;
    height: 50%;

    svg {
      fill: var(--foreground6);
    }
  }
}
