@import 'master';

home-buyer-registration {
  .home-buyer-registration-container {
    @include absolute(top 0 left 0);
    z-index: 6;
    width: 100%;
    height: 100%;
    @include flex-center-center();

    &.stick-right {
      right: 0;
      left: auto;
    }

    .home-buyer-registration {
      @include flex-center-center();
      flex-direction: column;
      color: var(--modal-foreground);
      font-size: 18px;
      width: 500px;
      position: relative;
      margin: 1rem;

      & > div {
        border-radius: var(--border-radius-modals);
        background-color: var(--modal-background-alpha-high);
        width: 100%;
        padding: 20px;
        margin-bottom: 10px;
      }

      .home-buyer-registration-header {
        padding: 2.2rem;
        @include flex-center-center();
        flex-direction: column;

        img {
          max-height: 10vh;
        }
      }

      .close-icon {
        @include absolute(top 0 right 0);
        width: 15px;
        height: 15px;
        margin: 0.8rem;
        cursor: pointer;

        svg {
          fill: var(--modal-foreground);
        }
      }

      .home-buyer-registration-form {
        @include flex-center-center();
        flex-direction: column;

        .home-buyer-registration-form-header {
          font-size: 22px;
          margin-bottom: 5px;
        }

        .home-buyer-registration-form-subheader {
          font-size: 15px;
          margin-bottom: 5px;
        }

        .home-buyer-registration-form-inputs {
          width: 100%;
          padding: 0 50px;
          margin-bottom: 5px;

          input,
          select {
            width: 100%;
          }

          .row {
            width: 100%;
            margin: 10px 0;
            @include flex-center-center();

            &:last-child {
              margin-bottom: 0;
            }
          }

          .col-3 {
            width: 33%;
            display: inline-block;

            input {
              height: 40px;
            }

            & + .col-3 {
              padding-left: 0.5rem;
            }
          }

          .name-line {
            display: flex;
            align-items: center;
            justify-content: space-between;

            input {
              width: 48%;
              margin: 0;
            }
          }
          .checkbox-field {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            input {
              width: auto;
            }
            label {
              font-size: 14px;
            }
            .checkbox-container {
              display: flex;
              align-items: center;
            }
          }
        }

        .home-buyer-registration-disclaimer {
          border-top: 1px solid var(--modal-foreground);
          padding: 1rem 0 0;
          margin-top: 1rem;
        }
      }

      .home-buyer-registration-disclaimer {
        font-size: 10px;
        padding: 10px;
        white-space: pre-wrap;

        input {
          display: inline;
        }
      }
    }
  }
}
